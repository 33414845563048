import * as React from 'react';
import { useRef, useEffect, useCallback, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import classNames from 'classnames';

import { Url } from '../url';
import * as Text from '../localisation';
import InViewTracker from '../components/in-view-tracker';
import TopMenu from '../components/top-menu';
import Footer from '../components/footer';
import Meta from '../components/meta';
import CtaBlockMobile from '../components/cta-block-mobile';
import Video from '../components/video';

import FeaturesTimeBlockingDesktop from '../images/main-features-timeblocking-desktop.mp4';
import FeaturesTimeBlockingMobile from '../images/main-features-timeblocking-mobile.mp4';

import * as classes from './index.module.css';
import {
  MainPageFeaturesTitleMobile,
  MainPageFeaturesBlock1Text,
  MainPageFeaturesBlock2Text1,
  MainPageFeaturesBlock2Text2,
  MainPageFeaturesBlock2TextMobile,
  MainPageFeaturesBlock3Text1,
  MainPageFeaturesBlock3Text2,
  MainPageFeaturesBlock3Text3,
  MainPageFeaturesBlock4Text,
  MainPageFeaturesBlock4TextMobile,
  MainPageFeaturesBlock5Text,
  MainPageFeaturesBlock5TextMobile,
  MainPageFeaturesBlock5Alt,
  MainPageFeaturesBlock6Text,
  MainPageFeaturesBlock6TextMobile,
  MainPageFeaturesBlock7Text1,
  MainPageFeaturesBlock7Text2,
  MainPageFeaturesBlock7Text3,
  MainPageFeaturesBlock8Title,
  MainPageFeaturesBlock8Text,
  MainPageFeaturesBlock8TextMobile,
  MainPageReviews1,
  MainPageReviews1Author,
  MainPageReviews1Company,
  MainPageReviews1PictureAlt,
  MainPageReviews2,
  MainPageReviews2Author,
  MainPageReviews2Company,
  MainPageReviews2PictureAlt,
  MainPageReviews3,
  MainPageReviews3Author,
  MainPageReviews3Company,
  MainPageReviews3PictureAlt,
  MainPageReviews4,
  MainPageReviews4Author,
  MainPageReviews4Company,
  MainPageReviews4PictureAlt,
  MainPageReviews5,
  MainPageReviews5Author,
  MainPageReviews5Company,
  MainPageReviews5PictureAlt,
  MainPageReviews6,
  MainPageReviews6Author,
  MainPageReviews6Company,
  MainPageReviews6PictureAlt,
  MainPageReviews7,
  MainPageReviews7Author,
  MainPageReviews7Company,
  MainPageReviews7PictureAlt,
  MainPageReviews8,
  MainPageReviews8Author,
  MainPageReviews8Company,
  MainPageReviews8PictureAlt,
  MainPageReviews9,
  MainPageReviews9Author,
  MainPageReviews9Company,
  MainPageReviews9PictureAlt,
  MainPageSubscriptionTitle,
  MainPageSubscriptionText1,
  MainPageEndingTitle, MainPageSubscriptionLink1,
} from '../localisation';

function CtaBlock({ showDetails, className }: { showDetails?: boolean, className?: string }) {
  return (
    <div className={`${classes.ctaBlock}`}>
      <Link to={Url.GetStarted} className={classNames(classes.ctaButton, `${className}`)}>
        {Text.MainPageShortCTA}
      </Link>
    </div>
  );
}

function IndexPage() {
  const planWrapperRef = useRef<HTMLDivElement>(null);
  const [slide, setSlide] = useState(0);

  const handleScroll = useCallback(() => {
    if (!planWrapperRef.current) {
      return;
    }

    const allHeight = planWrapperRef.current.getBoundingClientRect().height - 1000;
    const pageHeight = allHeight / 5;

    const position = planWrapperRef.current?.getBoundingClientRect().top;

    let slide = 0;

    if (position < 0) {
      slide = Math.min(Math.floor((position * -1) / pageHeight), 4);
    }


    setSlide(slide);
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);

    handleScroll();

    return () => {
      document.removeEventListener('scroll', handleScroll);
    }
  }, [])

  return (
    <>
      <Meta
        title={Text.MainPageTitle}
        url={Url.Main}
      />

      <TopMenu />

      <div className={classes.header}>

        <div className={classes.pageContent}>
          <div className={classes.headerInner}>
            <h1 className={classes.headerTitle}>
              {Text.MainPageHeaderTitle}
            </h1>

            <h2 className={classes.headerSubtitle}>
              {Text.MainPageHeaderSubtitle}
            </h2>

            <div className={classNames(classes.headerCTA, classes.desktop)}>
              <Link to={Url.GetStarted} className={classes.ctaButton}>
                {Text.MainPageShortCTA}
              </Link>
            </div>


            <div className={`${classes.headerPictureWrapper} ${classes.desktop}`}>
              <StaticImage
                src="../images/main-app.png"
                className={`${classes.picture} ${classes.headerPicture}`}
                alt={Text.MainPageHeaderAlt}
                imgStyle={{
                  objectFit: "contain",
                }}
              />
            </div>

            <CtaBlockMobile/>

          </div>
        </div>

        <div className={`${classes.headerPictureWrapper} ${classes.mobile}`}>
          <StaticImage
              src="../images/main-app-mobile.png"
              className={`${classes.picture} ${classes.headerPicture}`}
              alt={Text.MainPageHeaderAlt}
              imgStyle={{
                objectFit: "contain",
              }}
            />
        </div>
      </div>

      <div className={classes.pageContent}>
        <InViewTracker name="main-intro" className={classes.intro}>
          <p className={classNames(classes.introText, classes.subtitleMobile)}>
            {Text.MainPageIntroText}
          </p>

          <div className={classes.introCompanies}>
            <div className={classes.introCompany}>
              <StaticImage
                src="../images/main-intro-google.png"
                className={`${classes.picture} ${classes.companyPicture}`}
                alt={'Uber'}
                imgStyle={{
                  objectFit: "contain",
                }}
              />
            </div>
            <div className={classes.introCompany}>
              <StaticImage
                src="../images/main-intro-meta.png"
                className={`${classes.picture} ${classes.companyPicture}`}
                alt={'Meta'}
                imgStyle={{
                  objectFit: "contain",
                }}
              />
            </div>
            <div className={classes.introCompany}>
              <StaticImage
                src="../images/main-intro-bumble.png"
                className={`${classes.picture} ${classes.companyPicture}`}
                alt={'Bumble'}
                imgStyle={{
                  objectFit: "contain",
                }}
              />
            </div>
            <div className={classes.introCompany}>
              <StaticImage
                src="../images/main-intro-monzo.png"
                className={`${classes.picture} ${classes.companyPicture}`}
                alt={'Monzo'}
                imgStyle={{
                  objectFit: "contain",
                }}
              />
            </div>
            <div className={classes.introCompany}>
              <StaticImage
                src="../images/main-intro-maryland.png"
                className={`${classes.picture} ${classes.companyPicture}`}
                alt={'University of Maryland'}
                imgStyle={{
                  objectFit: "contain",
                }}
              />
            </div>
            <div className={classes.introCompany}>
              <StaticImage
                src="../images/main-intro-oakland.png"
                className={`${classes.picture} ${classes.companyPicture}`}
                alt={'Oaklands University'}
                imgStyle={{
                  objectFit: "contain",
                }}
              />
            </div>
          </div>

          <div className={classes.introBlocks}>
            <div className={classes.introBlock1}>
              <StaticImage
                src="../images/main-intro-1.png"
                className={`${classes.picture} ${classes.introBlockPicture}`}
                alt={Text.MainPageIntroBlock1Alt}
                imgStyle={{
                  objectFit: "contain",
                }}
              />
              <h3 className={classes.introBlockTitle}>
                {Text.MainPageIntroBlock1Title}
              </h3>
              <p className={classes.introBlockText}>
                {Text.MainPageIntroBlock1Text}
              </p>
            </div>
            <div className={classes.introBlock2}>
              <StaticImage
                src="../images/main-intro-2.png"
                className={`${classes.picture} ${classes.introBlockPicture}`}
                alt={Text.MainPageIntroBlock2Alt}
                imgStyle={{
                  objectFit: "contain",
                }}
              />
              <h3 className={classes.introBlockTitle}>
                {Text.MainPageIntroBlock2Title}
              </h3>
              <p className={classes.introBlockText}>
                {Text.MainPageIntroBlock2Text}
              </p>

            </div>
            <div className={classes.introBlock3}>
              <StaticImage
                src="../images/main-intro-3.png"
                className={`${classes.picture} ${classes.introBlockPicture}`}
                alt={Text.MainPageIntroBlock3Alt}
                imgStyle={{
                  objectFit: "contain",
                }}
              />
              <h3 className={classes.introBlockTitle}>
                {Text.MainPageIntroBlock3Title}
              </h3>
              <p className={classes.introBlockText}>
                {Text.MainPageIntroBlock3Text}
              </p>
            </div>
          </div>
        </InViewTracker>
      </div>

      <InViewTracker name="main-plan">
        <div ref={planWrapperRef} className={classes.plan} data-slide={slide}>
          <h2 className={classNames(classes.titleMobile, classes.planTitleMobile, classes.mobile)}>{Text.MainPagePlanTitleMobile}</h2>

          <div className={classes.planInner}>

            <div className={classes.pageContent}>
              <h2 className={classNames(classes.titleDesktop, classes.desktop)}>{Text.MainPagePlanTitle}</h2>

              <div className={classes.planPage}>
                <div>
                  <ol className={classes.planSteps}>
                    <li>{Text.MainPagePlanItem1}</li>
                    <li>{Text.MainPagePlanItem2}</li>
                    <li>{Text.MainPagePlanItem3}</li>
                    <li>{Text.MainPagePlanItem4}</li>
                    <li>{Text.MainPagePlanItem5}</li>
                  </ol>
                </div>
                <div className={classes.planPictureWrapper}>
                  <div className={classes.planPictureBack}/>
                  <div className={classes.planPictureList}>
                    <StaticImage
                      src="../images/main-plan-1.png"
                      className={`${classes.picture} ${classes.planPicture}`}
                      alt={Text.MainPagePlanItem1}
                      imgStyle={{
                        objectFit: "contain",
                      }}
                    />
                    <StaticImage
                      src="../images/main-plan-2.png"
                      className={`${classes.picture} ${classes.planPicture}`}
                      alt={Text.MainPagePlanItem1}
                      imgStyle={{
                        objectFit: "contain",
                      }}
                    />
                    <StaticImage
                      src="../images/main-plan-3.png"
                      className={`${classes.picture} ${classes.planPicture}`}
                      alt={Text.MainPagePlanItem1}
                      imgStyle={{
                        objectFit: "contain",
                      }}
                    />
                    <StaticImage
                      src="../images/main-plan-4.png"
                      className={`${classes.picture} ${classes.planPicture}`}
                      alt={Text.MainPagePlanItem1}
                      imgStyle={{
                        objectFit: "contain",
                      }}
                    />
                    <StaticImage
                      src="../images/main-plan-5.png"
                      className={`${classes.picture} ${classes.planPicture}`}
                      alt={Text.MainPagePlanItem1}
                      imgStyle={{
                        objectFit: "contain",
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className={classNames(classes.ctaBlockWrapper, classes.desktop)}>
                <CtaBlock className={classes.ctaButtonPlan}/>
              </div>

              <CtaBlockMobile/>
            </div>

          </div>
        </div>
      </InViewTracker>

      <div className={classes.features}>
        <InViewTracker name="main-features" className={classes.pageContent}>
          <h2 className={classNames(classes.titleDesktop, classes.desktop)}>{Text.MainPageFeaturesTitle}</h2>
          <h2 className={classNames(classes.titleMobile, classes.featuresTitleMobile, classes.mobile)}>{Text.MainPageFeaturesTitleMobile}</h2>

          <p className={classNames(classes.featuresSubtitle, classes.desktop)}>{Text.MainPageFeaturesText1}</p>
          <p className={classNames(classes.featuresSubtitle, classes.subtitleMobile, classes.mobile)}>{Text.MainPageFeaturesText1Mobile}</p>
        </InViewTracker>

        <div className={classes.featuresBlocks}>
          <div className={classes.featuresBlock}>
            <div className={classes.featuresBlockTextContent}>
              <h3>{Text.MainPageFeaturesBlock1Title}</h3>
              <p className={classes.featuresText}>{MainPageFeaturesBlock1Text}</p>
            </div>
            <StaticImage
              src="../images/main-features-day-by-day-planning.png"
              className={`${classes.picture} ${classes.desktop}`}
              alt={Text.MainPageFeaturesBlock1Alt}
              imgStyle={{
                objectFit: "contain",
              }}
            />

            <StaticImage
              src="../images/main-features-day-by-day-planning.png"
              className={`${classes.picture} ${classes.mobile}`}
              alt={Text.MainPageFeaturesBlock1Alt}
              imgStyle={{
                objectFit: "contain",
              }}
            />
          </div>
          <div className={classes.featuresBlock}>
            <div className={classes.featuresBlockTextContent}>
              <h3>{Text.MainPageFeaturesBlock2Title}</h3>
              <div className={classes.featuresTextWrapper}>
                <p className={classNames(classes.featuresText, classes.desktop)}>{MainPageFeaturesBlock2Text1}</p>
                <p className={classNames(classes.featuresText, classes.desktop)}>{MainPageFeaturesBlock2Text2}</p>
                <p className={classNames(classes.featuresText, classes.mobile)}>{MainPageFeaturesBlock2TextMobile}</p>
              </div>
            </div>
            <div className={classNames(classes.featuresTimeblockingVideo, classes.desktop)}>
              <Video
                className={classes.FeaturesVideoDesktop}
                video={FeaturesTimeBlockingDesktop}
                label="timeBlockingDesktop"
              />
            </div>

            <Video
              className={classNames(classes.featuresTimeblockingVideoMobile, classes.mobile)}
              video={FeaturesTimeBlockingMobile}
              label="timeBlockingMobile"
            />
          </div>
          <div className={classes.featuresBlock}>
            <div className={classes.featuresBlockTextContent}>
              <h3>{Text.MainPageFeaturesBlock3Title}</h3>
              <div className={classes.featuresTextWrapper}>
                <p className={classes.featuresText}>{MainPageFeaturesBlock3Text1}</p>
                <p className={classes.featuresText}>{MainPageFeaturesBlock3Text2}</p>
                <p className={classes.featuresText}>{MainPageFeaturesBlock3Text3}</p>
              </div>
            </div>
            <StaticImage
              src="../images/main-features-weekly-view.png"
              className={`${classes.picture} ${classes.desktop}`}
              alt={Text.MainPageFeaturesBlock3Alt}
              imgStyle={{
                objectFit: "contain",
              }}
            />

          </div>
          <div className={classes.featuresBlock}>
            <div className={classes.featuresBlockTextContent}>
              <h3>{Text.MainPageFeaturesBlock4Title}</h3>
              <p className={classes.featuresText}>{MainPageFeaturesBlock4Text}</p>
            </div>
            <StaticImage
              src="../images/main-features-calendar-integration.png"
              className={`${classes.picture} ${classes.desktop}`}
              alt={Text.MainPageFeaturesBlock4Alt}
              imgStyle={{
                objectFit: "contain",
              }}
            />

            <StaticImage
              src="../images/main-features-calendar-integration-mobile.png"
              className={`${classes.picture} ${classes.mobile}`}
              alt={Text.MainPageFeaturesBlock4Alt}
              imgStyle={{
                objectFit: "contain",
              }}
            />
          </div>
          <div className={classes.featuresBlock}>
            <div className={classes.featuresBlockTextContent}>
              <h3>{Text.MainPageFeaturesBlock5Title}</h3>
              <p className={classes.featuresText}>{MainPageFeaturesBlock5Text}</p>
            </div>
            <StaticImage
              src="../images/main-features-event-scheduling.png"
              className={`${classes.picture} ${classes.desktop}`}
              alt={Text.MainPageFeaturesBlock5Alt}
              imgStyle={{
                objectFit: "contain",
              }}
            />

            <StaticImage
              src="../images/main-features-event-scheduling-mobile.png"
              className={`${classes.picture} ${classes.mobile}`}
              alt={Text.MainPageFeaturesBlock5Alt}
              imgStyle={{
                objectFit: "contain",
              }}
            />
          </div>
          <div className={classes.featuresBlock}>
            <div className={classes.featuresBlockTextContent}>
              <h3>{Text.MainPageFeaturesBlock6Title}</h3>
              <p className={classes.featuresText}>{MainPageFeaturesBlock6Text}</p>
            </div>
            <StaticImage
              src="../images/main-features-note-creation.png"
              className={`${classes.picture} ${classes.desktop}`}
              alt={Text.MainPageFeaturesBlock6Alt}
              imgStyle={{
                objectFit: "contain",
              }}
            />

            <StaticImage
              src="../images/main-features-note-creation-mobile.png"
              className={`${classes.picture} ${classes.mobile}`}
              alt={Text.MainPageFeaturesBlock6Alt}
              imgStyle={{
                objectFit: "contain",
              }}
            />
          </div>
          <div className={classes.featuresBlock}>
            <div className={classes.featuresBlockTextContent}>
              <h3>{Text.MainPageFeaturesBlock7Title}</h3>
              <div className={classes.featuresTextWrapper}>
                <p className={classNames(classes.featuresText, classes.desktop)}>{MainPageFeaturesBlock7Text1}</p>
                <p className={classNames(classes.featuresText, classes.desktop)}>{MainPageFeaturesBlock7Text2}</p>
                <p className={classNames(classes.featuresText, classes.desktop)}>{MainPageFeaturesBlock7Text3}</p>

                <ul className={classNames(classes.list, classes.mobile)}>
                  <li>{Text.MainPageFeaturesBlock7Text1}</li>
                  <li>{Text.MainPageFeaturesBlock7Text2}</li>
                  <li>{Text.MainPageFeaturesBlock7Text3}</li>
                </ul>
              </div>
            </div>
            <StaticImage
              src="../images/main-features-task-management.png"
              className={`${classes.picture} ${classes.desktop} ${classes.featuresBlockPicture}`}
              alt={Text.MainPageFeaturesBlock7Alt}
              imgStyle={{
                objectFit: "contain",
              }}
            />

            <StaticImage
              src="../images/main-features-task-management-mobile.png"
              className={`${classes.picture} ${classes.mobile}`}
              alt={Text.MainPageFeaturesBlock7Alt}
              imgStyle={{
                objectFit: "contain",
              }}
            />
          </div>
          <div className={classes.featuresBlock}>
            <div className={classes.featuresBlockTextContent}>
              <h3>{Text.MainPageFeaturesBlock8Title}</h3>
                <p className={classes.featuresText}>{MainPageFeaturesBlock8Text}</p>
            </div>
            <StaticImage
              src="../images/main-features-collections.png"
              className={`${classes.picture} ${classes.desktop}`}
              alt={Text.MainPageFeaturesBlock8Alt}
              imgStyle={{
                objectFit: "contain",
              }}
            />

            <StaticImage
              src="../images/main-features-collections-mobile.png"
              className={`${classes.picture} ${classes.mobile}`}
              alt={Text.MainPageFeaturesBlock8Alt}
              imgStyle={{
                objectFit: "contain",
              }}
            />
          </div>
        </div>
      </div>

      <div className={classes.pageContent}>
        <InViewTracker className={classes.reviewsBlock} name="main-reviews">
          <h2 className={classes.desktop}>{Text.MainPageReviewsTitle}</h2>
          <h2 className={classNames(classes.titleMobile, classes.reviewTitleMobile, classes.mobile)}>
            {Text.MainPageReviewsTitleMobile}
          </h2>

          <div className={classNames(classes.reviewList, classes.desktop)}>
            <div className={classes.reviewColumn}>
              <div className={classes.review}>
                <p className={classes.reviewText}>{Text.MainPageReviews1}</p>
                <div className={classes.reviewUser}>
                  <div>
                    <div className={classes.reviewUserName}>{Text.MainPageReviews1Author}</div>
                    <div>{Text.MainPageReviews1Company}</div>
                  </div>
                </div>
              </div>
              <div className={classes.review}>
                <p className={classes.reviewText}>{Text.MainPageReviews4}</p>
                <div className={classes.reviewUser}>
                  <div>
                    <div className={classes.reviewUserName}>{Text.MainPageReviews4Author}</div>
                    <div>{Text.MainPageReviews4Company}</div>
                  </div>
                </div>
              </div>
              <div className={classes.review}>
                <p className={classes.reviewText}>{Text.MainPageReviews7}</p>
                <div className={classes.reviewUser}>
                  <StaticImage
                    src="../images/main-reviews-user-photo-4.png"
                    className={`${classes.picture} ${classes.reviewPicture}`}
                    alt={Text.MainPageReviews7PictureAlt}
                    imgStyle={{
                      objectFit: "contain",
                    }}
                  />
                  <div>
                    <div className={classes.reviewUserName}>{Text.MainPageReviews7Author}</div>
                    <div>{Text.MainPageReviews7Company}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.reviewColumn}>
              <div className={classes.review}>
                <p className={classes.reviewText}>{Text.MainPageReviews2}</p>
                <div className={classes.reviewUser}>
                  <div>
                    <div className={classes.reviewUserName}>{Text.MainPageReviews2Author}</div>
                    <div>{Text.MainPageReviews2Company}</div>
                  </div>
                </div>
              </div>
              <div className={classes.review}>
                <p className={classes.reviewText}>{Text.MainPageReviews5}</p>
                <div className={classes.reviewUser}>
                  <StaticImage
                    src="../images/main-reviews-user-photo-2.png"
                    className={`${classes.picture} ${classes.reviewPicture}`}
                    alt={Text.MainPageReviews5PictureAlt}
                    imgStyle={{
                      objectFit: "contain",
                    }}
                  />
                  <div>
                    <div className={classes.reviewUserName}>{Text.MainPageReviews5Author}</div>
                    <div>{Text.MainPageReviews5Company}</div>
                  </div>
                </div>
              </div>
              <div className={classes.review}>
                <p className={classes.reviewText}>{Text.MainPageReviews8}</p>
                <div className={classes.reviewUser}>
                  <StaticImage
                    src="../images/main-reviews-user-photo-5.png"
                    className={`${classes.picture} ${classes.reviewPicture}`}
                    alt={Text.MainPageReviews8PictureAlt}
                    imgStyle={{
                      objectFit: "contain",
                    }}
                  />
                  <div>
                    <div className={classes.reviewUserName}>{Text.MainPageReviews8Author}</div>
                    <div>{Text.MainPageReviews8Company}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.reviewColumn}>
              <div className={classes.review}>
                <p className={classes.reviewText}>{Text.MainPageReviews3}</p>
                <div className={classes.reviewUser}>
                  <StaticImage
                    src="../images/main-reviews-user-photo-1.png"
                    className={`${classes.picture} ${classes.reviewPicture}`}
                    alt={Text.MainPageReviews3PictureAlt}
                    imgStyle={{
                      objectFit: "contain",
                    }}
                  />
                  <div>
                    <div className={classes.reviewUserName}>{Text.MainPageReviews3Author}</div>
                    <div>{Text.MainPageReviews3Company}</div>
                  </div>
                </div>
              </div>
              <div className={classes.review}>
                <p className={classes.reviewText}>{Text.MainPageReviews6}</p>
                <div className={classes.reviewUser}>
                  <StaticImage
                    src="../images/main-reviews-user-photo-3.png"
                    className={`${classes.picture} ${classes.reviewPicture}`}
                    alt={Text.MainPageReviews6PictureAlt}
                    imgStyle={{
                      objectFit: "contain",
                    }}
                  />
                  <div>
                    <div className={classes.reviewUserName}>{Text.MainPageReviews6Author}</div>
                    <div>{Text.MainPageReviews6Company}</div>
                  </div>
                </div>
              </div>
              <div className={classes.review}>
                <p className={classes.reviewText}>{Text.MainPageReviews9}</p>
                <div className={classes.reviewUser}>
                  <div>
                    <div className={classes.reviewUserName}>{Text.MainPageReviews9Author}</div>
                    <div>{Text.MainPageReviews9Company}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={classNames(classes.reviewList, classes.mobile)}>
            <div className={classes.reviewListMobile}>
              <div className={classes.reviewMobile}>
                <p className={classes.reviewText}>{Text.MainPageReviews1}</p>
                <div className={classes.reviewUser}>
                  <div>
                    <div className={classes.reviewUserName}>{Text.MainPageReviews1Author}</div>
                    <div>{Text.MainPageReviews1Company}</div>
                  </div>
                </div>
              </div>
              <div className={classes.reviewMobile}>
                <p className={classes.reviewText}>{Text.MainPageReviews4}</p>
                <div className={classes.reviewUser}>
                  <div>
                    <div className={classes.reviewUserName}>{Text.MainPageReviews4Author}</div>
                    <div>{Text.MainPageReviews4Company}</div>
                  </div>
                </div>
              </div>
              <div className={classes.reviewMobile}>
                <p className={classes.reviewText}>{Text.MainPageReviews7}</p>
                <div className={classes.reviewUser}>
                  <StaticImage
                    src="../images/main-reviews-user-photo-4.png"
                    className={`${classes.picture} ${classes.reviewPicture}`}
                    alt={Text.MainPageReviews7PictureAlt}
                    imgStyle={{
                      objectFit: "contain",
                    }}
                  />
                  <div>
                    <div className={classes.reviewUserName}>{Text.MainPageReviews7Author}</div>
                    <div>{Text.MainPageReviews7Company}</div>
                  </div>
                </div>
              </div>
              <div className={classes.reviewMobile}>
                <p className={classes.reviewText}>{Text.MainPageReviews2}</p>
                <div className={classes.reviewUser}>
                  <div>
                    <div className={classes.reviewUserName}>{Text.MainPageReviews2Author}</div>
                    <div>{Text.MainPageReviews2Company}</div>
                  </div>
                </div>
              </div>
              <div className={classes.reviewMobile}>
                <p className={classes.reviewText}>{Text.MainPageReviews5}</p>
                <div className={classes.reviewUser}>
                  <StaticImage
                    src="../images/main-reviews-user-photo-2.png"
                    className={`${classes.picture} ${classes.reviewPicture}`}
                    alt={Text.MainPageReviews5PictureAlt}
                    imgStyle={{
                      objectFit: "contain",
                    }}
                  />
                  <div>
                    <div className={classes.reviewUserName}>{Text.MainPageReviews5Author}</div>
                    <div>{Text.MainPageReviews5Company}</div>
                  </div>
                </div>
              </div>
              <div className={classes.reviewMobile}>
                <p className={classes.reviewText}>{Text.MainPageReviews8}</p>
                <div className={classes.reviewUser}>
                  <StaticImage
                    src="../images/main-reviews-user-photo-5.png"
                    className={`${classes.picture} ${classes.reviewPicture}`}
                    alt={Text.MainPageReviews8PictureAlt}
                    imgStyle={{
                      objectFit: "contain",
                    }}
                  />
                  <div>
                    <div className={classes.reviewUserName}>{Text.MainPageReviews8Author}</div>
                    <div>{Text.MainPageReviews8Company}</div>
                  </div>
                </div>
              </div>
              <div className={classes.reviewMobile}>
                <p className={classes.reviewText}>{Text.MainPageReviews3}</p>
                <div className={classes.reviewUser}>
                  <StaticImage
                    src="../images/main-reviews-user-photo-1.png"
                    className={`${classes.picture} ${classes.reviewPicture}`}
                    alt={Text.MainPageReviews3PictureAlt}
                    imgStyle={{
                      objectFit: "contain",
                    }}
                  />
                  <div>
                    <div className={classes.reviewUserName}>{Text.MainPageReviews3Author}</div>
                    <div>{Text.MainPageReviews3Company}</div>
                  </div>
                </div>
              </div>
              <div className={classes.reviewMobile}>
                <p className={classes.reviewText}>{Text.MainPageReviews6}</p>
                <div className={classes.reviewUser}>
                  <StaticImage
                    src="../images/main-reviews-user-photo-3.png"
                    className={`${classes.picture} ${classes.reviewPicture}`}
                    alt={Text.MainPageReviews6PictureAlt}
                    imgStyle={{
                      objectFit: "contain",
                    }}
                  />
                  <div>
                    <div className={classes.reviewUserName}>{Text.MainPageReviews6Author}</div>
                    <div>{Text.MainPageReviews6Company}</div>
                  </div>
                </div>
              </div>
              <div className={classes.reviewMobile}>
                <p className={classes.reviewText}>{Text.MainPageReviews9}</p>
                <div className={classes.reviewUser}>
                  <div>
                    <div className={classes.reviewUserName}>{Text.MainPageReviews9Author}</div>
                    <div>{Text.MainPageReviews9Company}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </InViewTracker>
      </div>

      <div className={classNames(`${classes.subscription} ${classes.desktop}`)}>
        <InViewTracker name="main-subscription" className={classes.pageContent}>
          <h2 className={classes.subscriptionTitle}>{Text.MainPageSubscriptionTitle}</h2>
          <div className={classes.subscriptionList}>

            <div className={classes.subscriptionColumn}>
              <StaticImage
                src="../images/main-subscription-qr-code-iphone.png"
                className={`${classes.picture} ${classes.subscriptionPicture1}`}
                alt={Text.MainPageSubscriptionLink1}
                imgStyle={{
                  objectFit: "contain",
                }}
              />
              <div className={classes.subscriptionTextWrapper}>
                <p className={classes.subscriptionColumnText1}>{Text.MainPageSubscriptionText1}</p>
                <Link to={Url.ExternalAppStore} target="_blank" rel="nofollow" className={classes.subscriptionCtaLink}>
                  {Text.MainPageSubscriptionLink1}
                </Link>
              </div>
            </div>

            <div className={classes.subscriptionColumn}>
              <StaticImage
                src="../images/main-subscription-web.png"
                className={`${classes.picture} ${classes.subscriptionPicture2}`}
                alt={Text.MainPageSubscriptionCTA}
                imgStyle={{
                  objectFit: "contain",
                }}
              />
              <p className={classes.subscriptionColumnText2}>{Text.MainPageSubscriptionText2}</p>
              <Link to={Url.GetStarted} target="_blank" rel="nofollow" className={classes.subscriptionCtaButton}>
                {Text.MainPageSubscriptionCTA}
              </Link>
            </div>

            <div className={classes.subscriptionColumn}>
              <StaticImage
                src="../images/main-subscription-qr-code-android.png"
                className={`${classes.picture} ${classes.subscriptionPicture1}`}
                alt={Text.MainPageSubscriptionLink2}
                imgStyle={{
                  objectFit: "contain",
                }}
              />
              <div className={classes.subscriptionTextWrapper}>
                <p className={classes.subscriptionColumnText3}>{Text.MainPageSubscriptionText3}</p>
                <Link to={Url.ExternalGooglePlay} className={classes.subscriptionCtaLink}>
                  {Text.MainPageSubscriptionLink2}
                </Link>
              </div>
            </div>
          </div>
        </InViewTracker>
      </div>

      <div className={classes.pageContent}>
        <InViewTracker name="main-ending" className={classes.ending}>
          <StaticImage
            src="../images/main-ending-character.png"
            className={`${classes.picture} ${classes.endingPicture} ${classes.endingPictureMobile}`}
            alt={Text.MainPageEndingPictureAlt}
            imgStyle={{
              objectFit: "contain",
            }}
          />
          <div className={classes.endingTextWrapper}>
            <h2 className={classes.endingTitle}>{Text.MainPageEndingTitle}</h2>

            <p className={classNames(classes.endingText, classes.desktop)}>{Text.MainPageEndingText}</p>
            <p className={classNames(classes.endingText, classes.subtitleMobile, classes.mobile)}>
              {Text.MainPageEndingTextMobile}
            </p>
            <Link to={Url.DataProtection} className={classes.endingCtaLink}>
              {Text.MainPageEndingCTA}
            </Link>
          </div>

        </InViewTracker>
      </div>

      <Footer/>
    </>
  );
}

export default IndexPage;
